export default {
  "common": {
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENABLED"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISABLED"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONTH"])},
    "updated_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated by"])},
    "updated_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated on"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "message": {
    "create_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create success"])},
    "update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update success"])},
    "change_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to change the status of ", _interpolate(_named("type")), " <span>'", _interpolate(_named("name")), "'</span> from <span> '", _interpolate(_named("from")), "' </span> to <span> '", _interpolate(_named("to")), "' </span> ?"])},
    "end_stream": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to end stream <span>'", _interpolate(_named("name")), "'</span> ?"])}
  },
  "layout": {
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])}
  },
  "menu": {
    "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member List"])},
    "kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC Vertification"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Bonus"])},
    "stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "daily_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Report"])},
    "monthly_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Report"])},
    "s2e_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S2E Report"])},
    "user_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Report"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "pool_bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool Bet"])},
    "news_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Articles"])}
  },
  "base_table": {
    "row_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])}
  },
  "module": {
    "setting": {
      "add_youtube_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add youtube api key"])},
      "add_key_youtube": {
        "api_youtube_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
        "api_youtube_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      }
    },
    "stream": {
      "game_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game name"])},
      "add_reward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reward"])},
      "reward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rewards"])},
      "5_min_promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 MIN PROMOTION ID"])},
      "10_min_promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 MIN PROMOTION ID"])},
      "15_min_promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 MIN PROMOTION ID"])},
      "add_promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Promotion"])},
      "batch_update": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update  ", _interpolate(_named("total")), " streamers"])},
      "stream_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream ID"])},
      "game_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Title"])},
      "streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer"])},
      "stream_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Title"])},
      "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
      "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
      "running_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running Time"])},
      "total_viewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Viewers"])},
      "peak_viewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peak Viewers"])},
      "current_viewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Viewers"])},
      "total_djt_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total ESC Claimed"])},
      "djt_claimed_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC Claim Count"])},
      "watch_2_earn_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WATCH 2 EARN ENABLED"])},
      "watch_2_earn_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WATCH 2 EARN DISABLED"])},
      "get_channel_yt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get channel youtube"])},
      "channel_yt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL channel youtube"])},
      "channel_youtube_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel youtube id"])},
      "channel_yt_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel youtube id"])},
      "url_youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url youtube channel"])},
      "twitch_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWITCH ID"])},
      "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Added"])},
      "missed_djt_claims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missed ESC Claims count"])},
      "live_stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live streams"])},
      "stream_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream history"])},
      "streamer_stream_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIVE STREAM HISTORY"])},
      "past_stream_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAST STREAM HISTORY"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add streamer"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update streamer"])},
      "view_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View history"])},
      "start_watch_2_earn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start watch 2 Earn"])},
      "stop_watch_2_earn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop watch 2 Earn"])},
      "please_enter_passcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter passcode"])},
      "table": {
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
        "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
        "streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer"])},
        "game_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Title"])},
        "stream_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream ID"])},
        "stream_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Title"])},
        "match_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match ID"])},
        "run_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Time"])},
        "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Viewers"])},
        "total_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Viewers"])},
        "peak_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peak Viewers"])},
        "djt_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC Claimed"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
        "streamer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer Name"])},
        "streamer_twitch_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer Twitch ID"])},
        "streamer_youtube_channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel youtube name"])},
        "watch_2_earn_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch 2 Earn Status"])},
        "watch_2_earn_start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch 2 Earn Start Time"])},
        "watch_2_earn_end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch 2 Earn End Time"])},
        "stream_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Status"])},
        "total_stream_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Stream Count"])},
        "total_stream_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Stream Time"])},
        "total_djt_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total ESC Claimed"])},
        "total_past_stream_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Past Stream Count"])},
        "total_past_stream_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Past Stream Time"])},
        "total_past_stream_djt_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total ESC Claimed"])},
        "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming Platform"])}
      },
      "create_streamer": {
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
        "profile_image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image url"])},
        "twitch_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitch login"])},
        "youtube_channel_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube id"])},
        "streamer_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer name"])},
        "five_minutes_promotion_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Five minutes promotion id"])},
        "ten_minutes_promotion_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten minutes promotion id"])},
        "fifteen_minutes_promotion_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fifteen minutes promotion id"])},
        "djt_amount_per_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC amount per hour"])},
        "djt_per_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC per hour"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "promotion_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion ID"])},
        "remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remark"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFILE PICTURE (64x64 px)"])},
        "livestream_reward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIVE STREAM REWARDS PER HOUR"])},
        "video_reward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAST STREAM REWARDS PER HOUR"])},
        "partner_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTNER STATUS"])},
        "stream_to_earn_rate_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STREAM TO EARN RATE (LIVE)"])},
        "stream_to_earn_rate_past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STREAM TO EARN RATE (PAST)"])},
        "affiliate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate(s)"])},
        "live_stream_rewards_per_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Stream Rewards Per Hour"])},
        "past_stream_rewards_per_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Stream Rewards Per Hour"])}
      },
      "viewer": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current viewers"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total viewers"])},
        "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewers"])},
        "table": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER NAME"])},
          "total_view_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL VIEW TIME"])},
          "resume_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESUME COUNT"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time"])},
          "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event"])},
          "djt_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC AMOUNT"])},
          "djt_claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC CLAIMED THIS STREAM"])},
          "start_viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Viewing"])},
          "resume_viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumed Viewing"])},
          "end_viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Viewing"])},
          "djt_claim_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC Claim Available"])},
          "djt_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC Claimed"])},
          "djt_claim_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESC Claim Expired"])}
        }
      }
    },
    "auth": {
      "sign_in": {
        "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
        "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
        "remember_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
        "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Watch to earn BO page"])}
      }
    },
    "user": {
      "user_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List user"])},
      "user_stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WATCH 2 EARN HISTORY"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])},
      "esb_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESB USER ID"])},
      "current_djt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CURRENT ESC"])},
      "remainging_seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REMAINGING SECONDS"])}
    },
    "report": {
      "daily": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
        "total_viewers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total viewers count"])},
        "peak_viewers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peak viewers count"])},
        "total_view_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total view time"])},
        "total_djt_claimed_viewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total esc claimed viewers"])},
        "total_djt_claim_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total esc claim count"])},
        "total_djt_amount_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total esc amount claimed"])},
        "streamer_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer count"])},
        "stream_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream count"])}
      },
      "s2e_report": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S2E Report"])},
        "table": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamers"])},
          "stream_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Duration"])},
          "total_view_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total View Time"])},
          "total_viewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Viewers"])},
          "total_s2e_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Earning (USDT)"])},
          "total_s2e_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Claimed (USDT)"])},
          "total_claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Claimed"])},
          "total_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Earning"])}
        },
        "detail": {
          "table": {
            "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
            "total_stream_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Duration"])},
            "total_view_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Time"])},
            "total_viewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewers"])},
            "total_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earning (USDT)"])},
            "ste_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claimed (USDT)"])}
          }
        }
      },
      "user_report": {
        "table": {
          "esb_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESB USER ID"])},
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
          "total_view_times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watched Hours Per Day"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
        }
      }
    },
    "pool_bet": {
      "bet_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BET HISTORY"])},
      "table": {
        "esb_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esb User Id"])},
        "match_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match ID"])},
        "league_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["League Name"])},
        "league_name_cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["League Name Cn"])},
        "league_name_tw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["League Name Tw"])},
        "sport_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "team_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM"])},
        "team_abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Abbreviation"])},
        "team_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Image"])},
        "bet_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet Type"])},
        "game_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Order"])},
        "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
        "bet_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet Amount"])},
        "win_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Won Or Lost Amount"])},
        "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
        "bet_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet Status"])},
        "paid_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Status"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])}
      }
    }
  },
  "axios": {
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect credentials"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error connecting to the server. Please try again in a few minutes"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many requests"])}
  }
}